<template>
  <div class="statistics-total-card">
    <div class="statistics-total-card-content">
      <div title v-html="card.title"></div>
      <div icon><iconic :name="card.icon" :original="true" /></div>
      <div info>
        <div mount>{{ $global.number_format(card.total || 0) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card"],
};
</script>

<style lang="scss" scoped>
.statistics-total-card {
  background: #f4f4f4;
  border-radius: $mradius;
  box-shadow: 1px 1px 4px rgba(72, 0, 0, 0.3), inset -2px -4px 4px rgba(106, 1, 1, 0.3), inset 0px 4px 2px rgba(255, 197, 197, 0.3);
  &-content {
    padding: $mpadding;
    text-align: center;
    [icon] {
      font-size: 46px;
    }
    [info] {
      text-align: center;
      [title] {
        margin: 0 0 $mpadding/4 0;
      }
      [mount] {
        color: $primary_color;
        font-weight: bold;
        margin: $mpadding/2 0 0 0;
        font-size: 110%;
      }
    }
  }
}
</style>
